import { isIE } from '@itslearning/atlas/detectors/UserAgentHelper';

export function copyOwner(owner) {
    return {
        name: owner.name,
        email: owner.email,
        summary: owner.summary,
        pictureUrl: owner.pictureUrl,
        hasUploadedPhoto: owner.hasUploadedPhoto,
    };
}

export function createAntiForgeryHeader(antiForgeryHeaderSettings) {
    const antiForgeryHeader = {};

    antiForgeryHeader[antiForgeryHeaderSettings.headerName] = antiForgeryHeaderSettings.headerValue;

    return antiForgeryHeader;
}

export function copyEPortfolioSettings(settings) {
    return {
        ePortfolioId: settings.ePortfolioId,
        useBlog: settings.useBlog,
        accessLevelType: settings.accessLevelType,
        password: settings.password,
        inheritAccessLevel: settings.inheritAccessLevel,
        discSpaceUsedText: settings.discSpaceUsedText
    };
}

export function dispatchEvent(event) {
    if (isIE()) {
        const ieEvent = document.createEvent('CustomEvent');

        ieEvent.initCustomEvent(event, false, false, {});
        window.dispatchEvent(ieEvent);
    } else {
        window.dispatchEvent(new CustomEvent(event));
    }
}
