// NOTE: must be synchronized with ViewablePortfolioItemFileType.cs
export const fileType = {
    undefined: 0,
    image: 1,
    audio: 2,
    video: 3,
    pdf: 4,
    word: 5,
    excel: 6,
    powerPoint: 7
};

// NOTE: must be synchronized with EPortfolioUserPictureAction enum
export const userPictureAction = {
    none: 0,
    uploadNew: 1,
    delete: 2
};

export const contentMode = {
    loadingInProgress: 1,
    errorOccurred: 2,
    showPortfolioDescription: 3,
    showPortfolioItem: 4
};

// NOTE: must be synchronized with ExportStatus enum
export const exportStatus = {
    pending: 1,
    inProgress: 2,
    completed: 3,
    failed: 4
};

// NOTE: must be synchronized with PortfolioCourseAccessLevel and EPortfolioAccessLevel enum
export const accessLevels = {
    letOwnersChangeAccessLevel: 0,
    owner: 1,
    administratorsInCourse: 2,
    everybodyInCourse: 3,
    coursesAndProjects: 4,
    site: 5,
    internet: 6,
    school: 8
};
