<h1>{portfolioItem.title}</h1>
{#if portfolioItem.isDraft}
    <span class="draft-label">{terms.draft}</span>
{/if}

{@html portfolioItem.description}

{#if portfolioItem.postedDate}
    <div class="posted-date">{portfolioItem.postedDate}</div>
{/if}

<script>
    export let portfolioItem = {};
    export let terms = {};
</script>
