<section class="comments">
    {#if comments.length > 0}
        <section class="comments-list">
            <h2>{terms.comments} ({comments.length})</h2>
            {#each comments as comment}
                <CommentItem
                    canEdit={canEdit}
                    comment={comment}
                    terms={terms}
                    on:showProfileCard={showProfileCard}
                    on:deleteComment={showDeleteConfirm} />
            {/each}
        </section>
    {/if}

    <section class="add-new-comment">
        {#if showErrorWhilePostingComment}
            <Banner
                type="warning"
                text={terms.errorOccurred}
                closable={true}
                close={terms.close}
                visible={true}
                on:hidden={hideErrorWhilePostingComment} />
        {/if}

        <div class="new-comment">
            <Avatar avatarSize={300} src={personProfilePictureUrl}/>
            <TextField
                type="area"
                class="new-comment-input"
                maxlength={1024}
                placeholder={terms.writeComment}
                bind:value={newCommentText}
                height="small"
                labelHidden={true} />
        </div>

        <div class="add-new-comment-button">
            <Button
                type="secondary"
                htmlType="button"
                text={terms.addComment}
                disabled={!inputIsCorrect}
                on:click={postComment} />
        </div>
    </section>

    <div bind:this={confirmDelete}>
        {#if showDeleteConfirmation}
            <Confirm
                destructive={true}
                heading={terms.pleaseConfirm}
                body={terms.deleteConfirmation}
                {...confirmButtonText}
                on:modalConfirmed={deleteComment}
                on:modalCancelled={resetEventToDelete} />
        {/if}
    </div>
</section>

<script>
    import { tick } from 'svelte';

    import Avatar from '@itslearning/prometheus/assets/nodes/Avatar/v1/Avatar.svelte';
    import Button from '@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte';
    import TextField from '@itslearning/prometheus/assets/inputs/TextField/v1/TextField.svelte';
    import Banner from '@itslearning/prometheus/assets/feedback/Banner/v1/Banner.svelte';
    import Confirm from '@itslearning/prometheus/assets/modals/Confirm/v1/Confirm.svelte';
    import CommentItem from './CommentItem.svelte';
    import { del, post } from '@itslearning/atlas/network/fetch';
    import { createAntiForgeryHeader } from '../common';

    export let comments = [];
    export let terms = {};
    export let antiForgeryHeaderSettings = {};
    export let personProfilePictureUrl = '';
    export let postCommentUrl = '';
    export let newCommentText = '';
    export let canEdit = false;
    export let showErrorWhilePostingComment = false;
    export let showDeleteConfirmation = false;
    export let deleteDetails = {};

    $: inputIsCorrect = newCommentText && newCommentText.trim().length > 0;
    $: confirmButtonText = ({ i18n: { CONFIRM: terms.delete, CANCEL: terms.cancel } });

    let confirmDelete;

    function showProfileCard(event) {
        const personId = event.detail.personId;

        if (document.showProfileCard) {
            document.showProfileCard(personId);
        } else if (window.top.document.showProfileCard) {
            window.top.document.showProfileCard(personId);
        }
    }

    async function showDeleteConfirm(event) {
        showDeleteConfirmation = true;
        deleteDetails = event.detail;
        await tick();
        confirmDelete.querySelector('.prom-button__destructive').focus();
    }

    function deleteComment() {
        if (!deleteDetails.deleteUrl || deleteDetails.deleteUrl.length === 0) {
            return;
        }

        del(deleteDetails.deleteUrl, undefined, getAntiForgeryHeader())
            .then(() => {
                comments = comments.filter((comment) => comment.commentId !== deleteDetails.commentId);
                resetEventToDelete();
            })
            .catch(() => {
                resetEventToDelete();
            });
    }

    function resetEventToDelete() {
        showDeleteConfirmation = false;
        deleteDetails = undefined;
    }

    function postComment() {
        post(postCommentUrl, { CommentText: newCommentText }, getAntiForgeryHeader())
            .then((postedComment) => {
                comments.push(postedComment);
                comments = comments;
                newCommentText = '';
            })
            .catch(() => {
                showErrorWhilePostingComment = true;
            });
    }

    function hideErrorWhilePostingComment() {
        showErrorWhilePostingComment = false;
    }

    function getAntiForgeryHeader() {
        return {
            headers: createAntiForgeryHeader(antiForgeryHeaderSettings)
        };
    }
</script>
