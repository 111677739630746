<svelte:window on:startNewExport={onStartNewExport}></svelte:window>

{#if currentExportResult && currentExportStatus}
    {#if currentExportStatus.hasError && showExportFailedWarning}
        <Banner type="warning" visible={true} text={terms.errorOccurredTryNewExportLater} closable={true} close={terms.close} />
    {:else if !currentExportStatus.hasError && currentExportStatus.title}
        <div class="export-container" >
            <span class="screen-reader">{terms.exportPortfolio}</span>
            <img class="export-image" src={iconUrl} alt="" />
            <div class="export-status-container">
                <span class="export-status-title">
                    <span>{currentExportStatus.title}</span>
                    {#if currentExportStatus.inProgress}
                        <span class="export-in-progress" />
                    {/if}
                </span>
                <span class="export-status-details">{currentExportStatus.details}</span>
                <span>
                    <Button cssClass="download-button"
                        text={terms.downloadFile}
                        type="secondary"
                        htmlType="submit"
                        disabled={currentExportStatus.inProgress ? true : false}
                        on:click={onDownloadButtonClick} />
                    {#if !currentExportStatus.inProgress}
                        <Button cssClass="delete-export-button"
                            text={terms.deleteFile}
                            type="plain"
                            htmlType="button"
                            disabled={currentExportStatus.hasError ? true : false}
                            on:click={onDeleteButtonClick} />
                    {/if}
                </span>
            </div>

            <div bind:this={exportConfirm}>
                {#if showExportConfirm}
                    <Confirm
                        heading={terms.pleaseConfirm}
                        on:modalConfirmed={onExportConfirmed}
                        on:modalCancelled={onExportConfirmClosed}
                        body={terms.newExportConfirmText}
                        {...confirmButtonText}
                        destructive={false}
                    />
                {/if}
            </div>

            <div bind:this={exportInProgressAlert}>
                <Alert heading={terms.alert} bind:enabled={showExportInProgressAlert} on:close={onExportAlertClosed} {...alertButtonText}>
                    {terms.exportInProgressAlert}
                </Alert>
            </div>
        </div>
    {/if}
{/if}

<script>
    import { onMount, onDestroy, tick } from 'svelte';

    import Alert from '@itslearning/prometheus/assets/modals/Alert/v1/Alert.svelte';
    import Banner from '@itslearning/prometheus/assets/feedback/Banner/v1/Banner.svelte';
    import Button from '@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte';
    import Confirm from '@itslearning/prometheus/assets/modals/Confirm/v1/Confirm.svelte';
    import { exportStatus } from '../constants';
    import { createAntiForgeryHeader } from '../common';
    import { get, post, del } from '@itslearning/atlas/network/fetch';

    export let currentExportResult = {};
    export let terms = {};
    export let antiForgeryHeaderSettings = {};
    export let iconUrl = '';
    export let exportUrl = '';
    export let exportPollingTimeout = 0;
    export let showExportConfirm = false;
    export let showExportInProgressAlert = false;
    export let showExportFailedWarning = false;

    $: currentExportStatus = getCurrentExportStatus(currentExportResult, terms);
    $: confirmButtonText = ({
        i18n: { CONFIRM: terms.yes, CANCEL: terms.no }
    });
    $: alertButtonText = ({
        i18n: { CONFIRM: terms.ok }
    });
    
    let exportInProgressAlert, exportConfirm;

    onMount(() => startPollingIfNeed());

    onDestroy(() => stopPolling());

    function getCurrentExportStatus(currentExportResult, terms) {
        const status = {
            hasError: false,
            title: '',
            details: '',
            inProgress: false
        };

        if (!currentExportResult) {
            return status;
        }

        switch (currentExportResult.status) {
            case exportStatus.pending:
            case exportStatus.inProgress:
                status.title = terms.exportInProgress;
                status.details = terms.exportInProgressReturnLater;
                status.inProgress = true;
                break;
            case exportStatus.completed:
                status.title = currentExportResult.title;
                status.details = currentExportResult.completedDateTime;
                break;
            case exportStatus.failed:
                status.hasError = true;
                break;
        }

        return status;
    }

    function startNewExport() {
        showExportConfirm = false;
        showExportFailedWarning = false;

        post(exportUrl, {}, { headers: createAntiForgeryHeader(antiForgeryHeaderSettings) })
            .then((result) => {
                currentExportResult = result;
                startPollingIfNeed();
            })
            .catch(() => {
                currentExportResult.status = exportStatus.failed;
                currentExportResult = currentExportResult;
                showExportFailedWarning = true;
            });
    }

    function startPollingIfNeed() {
        if (currentExportResult && (currentExportResult.status === exportStatus.inProgress || currentExportResult.status === exportStatus.pending)) {
            const timeout = setInterval(() => {
                get(exportUrl)
                    .then((newExportResult) => {
                        if (currentExportResult.status !== newExportResult.status) {
                            currentExportResult = newExportResult;

                            if (newExportResult.status === exportStatus.failed) {
                                showExportFailedWarning = true;
                            }

                            if (newExportResult.status === exportStatus.completed || newExportResult.status === exportStatus.failed) {
                                stopPolling();
                            }
                        }
                    })
                    .catch(() => {
                        showExportFailedWarning = true;
                        stopPolling();
                    });
            }, 5000);

            exportPollingTimeout = timeout;
        }
    }

    function stopPolling() {
        const timeout = exportPollingTimeout;

        clearInterval(timeout);
        exportPollingTimeout = timeout;
    }

    async function onStartNewExport() {
        if (currentExportResult &&
            (currentExportResult.status === exportStatus.inProgress ||
            currentExportResult.status === exportStatus.pending)) {

            showExportInProgressAlert = true;
            await tick();
            const alertButton = exportInProgressAlert.querySelector('.prom-button__primary');

            if (alertButton) {
                alertButton.focus();
            }

            return;
        }

        if (currentExportResult && currentExportResult.status && currentExportResult.status !== exportStatus.failed) {
            showExportConfirm = true;
        } else {
            startNewExport();
        }
    }

    function onExportConfirmed() {
        startNewExport();
    }

    function onExportConfirmClosed() {
        showExportConfirm = false;
    }

    function onExportAlertClosed() {
        showExportInProgressAlert = false;
    }

    function onDownloadButtonClick() {
        window.open(currentExportResult.downloadExportUrl, '_top');
    }

    function onDeleteButtonClick() {
        del(exportUrl, undefined, { headers: createAntiForgeryHeader(antiForgeryHeaderSettings) })
            .then((result) => {
                currentExportResult = {};
                showExportFailedWarning = false;
            });
    }
</script>
