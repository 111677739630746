<li class={itemCssClass}>
    {#if settings.text}
        {#if settings.url}
        <a href={settings.url} on:click={onLinkClick}>
            <ItemText iconUrl={settings.iconUrl}
                text={settings.text}
                badgeNumber={settings.badgeNumber}
                badgeTitle={settings.badgeTitle}
                isDraft={settings.isDraft} />
        </a>
        {:else}
        <span>
            <ItemText iconUrl={settings.iconUrl} text={settings.text} />
        </span>
        {/if}
    {/if}
    <slot></slot>
</li>

<script>
    import { createEventDispatcher } from 'svelte';

    import ItemText from './ItemText.svelte';

    export let settings = {};
    export let isFolder = false;

    const dispatch = createEventDispatcher();

    $: itemCssClass = getItemCssClass(isFolder, settings);

    function getItemCssClass(isFolder, settings) {
        const cssClasses = [];

        if (isFolder) {
            cssClasses.push('folder');
        }

        if (settings.selected) {
            cssClasses.push('selected');
        }

        if (!settings.text) {
            cssClasses.push('no-text');
        }

        return cssClasses.join(' ');
    }

    function onLinkClick(event) {
        dispatch('navigation', {
            apiUrl: settings.apiUrl,
            event: event
        });
    }
</script>