<PortfolioItemPreview {portfolioItem} {terms} />
<FileList files={portfolioItem.files} downloadText={terms.download} previewText={terms.preview} />
{#if portfolioItem.showComments}
    <CommentsList
        {canEdit}
        {antiForgeryHeaderSettings}
        {personProfilePictureUrl}
        terms={terms.commentTerms}
        comments={portfolioItem.comments}
        postCommentUrl={portfolioItem.urls.postComment} />
{/if}

<script>
    import PortfolioItemPreview from './PortfolioItemPreview.svelte';
    import FileList from '../FileList/FileList.svelte';
    import CommentsList from '../Comments/CommentsList.svelte';

    export let portfolioItem = {};
    export let terms = {};
    export let antiForgeryHeaderSettings = {};
    export let personProfilePictureUrl = null;
    export let canEdit = false;
</script>
