{#if files && files.length}
    <ul class="file-list">
        {#each files as file}
            <li class="file-item">
                <img src={file.iconUrl} alt={file.name} />
                <span class="file-info-container">
                    <span class="file-info">
                        <span class="file-name">{file.name}</span>
                        {#if file.modifiedDT && file.modifiedDT.length > 0}
                            <span class="file-date">{file.modifiedDT}</span>
                        {/if}
                    </span>
                    <span class="file-actions">
                        {#if file.previewUrl && file.previewUrl.length > 0}
                            <Button
                                type="basic-negative"
                                text={previewText}
                                htmlType="button"
                                on:click={() => previewFile(file)} />
                        {/if}

                        {#if file.downloadUrl && file.downloadUrl.length > 0}
                            <Button
                                type="secondary"
                                text={downloadText}
                                htmlType="button"
                                on:click={() => downloadFile(file)} />
                        {/if}
                    </span>
                </span>
            </li>
        {/each}
    </ul>
{/if}

{#if previewModal && previewModal.open}
    <Modal bind:this={modal} heading={previewModal.fileName} on:modalClosed={closePreviewModal} showCloseButton="true">
        <div slot="body">
            {#if imagePreview}
                <img src={previewModal.url} alt={previewModal.fileName} />
            {:else if audioPreview}
                <audio controls src={previewModal.url} />
            {:else if videoPreview}
                <!-- svelte-ignore a11y-media-has-caption -->
                <video src={previewModal.url} controls />
            {/if}
        </div>
    </Modal>
{/if}

<script>
    import { tick } from 'svelte';

    import Modal from '@itslearning/prometheus/assets/modals/Modal/v1/Modal.svelte';
    import Button from '@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte';
    import { get } from '@itslearning/atlas/network/fetch';
    import { fileType } from '../constants';

    export let files = [];
    export let downloadText = '';
    export let previewText = '';
    export let previewModal = {};

    $: imagePreview = previewModal && previewModal.type === fileType.image;
    $: audioPreview = previewModal && previewModal.type === fileType.audio;
    $: videoPreview = previewModal && previewModal.type === fileType.video;

    let modal;

    function downloadFile(file) {
        if (file.downloadUrl && file.downloadUrl.length > 0) {
            window.open(file.downloadUrl);
        }
    }

    async function previewFile(file) {
        if (!file.previewUrl || file.previewUrl.length === 0) {
            downloadFile(file);

            return;
        }

        /* eslint-disable no-case-declarations */
        switch (file.fileType) {
            case fileType.image:
            case fileType.audio:
            case fileType.video:
                const url = await get(file.previewUrl);

                previewModal = {
                    open: true,
                    fileName: file.name,
                    type: file.fileType,
                    url: url
                };
                await tick();
                document.querySelector('button.prom-modal__close').focus();
                break;
            case fileType.pdf:
            case fileType.word:
            case fileType.excel:
            case fileType.powerPoint:
                window.open(file.previewUrl);
                break;
            default:
                downloadFile(file);
                break;
        }
    }

    function closePreviewModal() {
        previewModal = undefined;
    }
</script>
