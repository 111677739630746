<section class="portfolio-assessment">
    {#if teacher && teacher.personName && teacher.profileImageUrl}
        <div class="portfolio-assessment-row portfolio-assessment-teacher">
            <Avatar src={teacher.profileImageUrl} size=200 />
            <Button type="plain" htmlType="button" text={teacher.personName} on:click={() => showProfileCard(teacher)} />
        </div>
    {/if}
    <div class="portfolio-assessment-row portfolio-assessment-grade">
        <span>{terms.portfolioAssessment}</span>
        {#if selectedAssessmentItemId === 0 && !comment}
            <span class="portfolio-assessment-not-assessed">{terms.notAssessed}</span>
        {:else if selectedAssessmentItemId > 0 && grade}
            <span class="portfolio-assessment-grade-badge">{grade}</span>
        {/if}
    </div>
    {#if comment}
        <div class="portfolio-assessment-row portfolio-assessment-comment">
            <span>{comment}</span>
        </div>
    {/if}
    {#if canAssess}
        <div class="portfolio-assessment-row">
            <Button type="primary" text={assessButtonText} htmlType="button" on:click={assessButtonClick} />
        </div>
    {/if}

    {#if showAssessDialog}
        <Modal heading={assessButtonText}>
            <div slot="body">
                {#if assessmentItems && assessmentItems.length > 0}
                    <Select label={terms.assessment} bind:value={unsavedAssessmentItemId}>
                        {#each assessmentItems as assessmentItem}
                            <option value={assessmentItem.id}>{assessmentItem.title}</option>
                        {/each}
                    </Select>
                {/if}
                <TextField label={terms.comment} type="area" height="medium" placeholder={terms.comment} maxlength="2048" bind:value={unsavedComment} />
            </div>
            <div slot="footer">
                <Button bind:this={setAssessment} type="primary" text={terms.save} htmlType="button" on:click={setAssessmentClick} />
                <Button bind:this={cancel} type="secondary" text={terms.cancel} htmlType="button" on:click={cancelClick} />
            </div>
        </Modal>
    {/if}
</section>

<script>
    import Avatar from '@itslearning/prometheus/assets/nodes/Avatar/v1/Avatar.svelte';
    import Button from '@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte';
    import Modal from '@itslearning/prometheus/assets/modals/Modal/v1/Modal.svelte';
    import Select from '@itslearning/prometheus/assets/inputs/Select/v1/Select.svelte';
    import TextField from '@itslearning/prometheus/assets/inputs/TextField/v1/TextField.svelte';
    import { post } from '@itslearning/atlas/network/fetch';
    import { createAntiForgeryHeader } from '../common';

    export let canAssess = false;
    export let grade = '';
    export let comment = '';
    export let teacher = {};
    export let selectedAssessmentItemId = 0;
    export let setAssessmentUrl = '';
    export let assessmentItems = [];
    export let terms = {};
    export let showAssessDialog = false;
    export let antiForgeryHeaderSettings = {};
    export let portfolioId = 0;
    export let unsavedAssessmentItemId = 0;
    export let unsavedComment = 0;

    $: assessButtonText = selectedAssessmentItemId > 0 || comment ? terms.editAssessment : terms.setAssessment;

    let setAssessment, cancel;

    function assessButtonClick() {
        unsavedAssessmentItemId = selectedAssessmentItemId;
        unsavedComment = comment;
        showAssessDialog = true;
    }

    function setAssessmentClick() {
        post(setAssessmentUrl, {
            portfolioId,
            assessmentItemId: unsavedAssessmentItemId,
            comment: unsavedComment
        }, {
            headers: createAntiForgeryHeader(antiForgeryHeaderSettings)
        })
            .then((response) => {
                fillPropertiesFromResponse(response);
            })
            .catch((e) => {
                console.log(e.message);
            })
            .finally(() => {
                showAssessDialog = false;
            });
    }

    function cancelClick() {
        showAssessDialog = false;
    }

    function showProfileCard(teacher) {
        if (document.showProfileCard) {
            document.showProfileCard(teacher.personId);
        } else if (window.top.document.showProfileCard) {
            window.top.document.showProfileCard(teacher.personId);
        }
    }

    function fillPropertiesFromResponse(response) {
        grade = response.grade;
        comment = response.comment;
        teacher = response.teacher;
        selectedAssessmentItemId = response.selectedAssessmentItemId;
    }
</script>
