<div class="comment">
    <Avatar avatarSize={300} src={comment.profilePictureUrl} title={comment.personName}/>
    <div class="info">
        <span class="person">
            {#if canEdit && comment.canSeePersonProfile}
                <Button
                    type="plain"
                    htmlType="button"
                    text={comment.personName}
                    on:click={showProfileCard}
                    cssClass="view-person-button" />
            {:else}
                <span class="person-text">{comment.personName}</span>
            {/if}
            {#if canEdit && comment.isNew}
                <span class="new">{terms.new}</span>
            {/if}
        </span>
        <span class="published">{comment.publishedDate}</span>
        <span class="text">{@html comment.commentText}</span>
        {#if comment.deleteUrl && comment.deleteUrl.length > 0}
            <span class="delete-comment">
                <Button
                    type="plain"
                    htmlType="button"
                    text={terms.deleteComment}
                    on:click={deleteComment}
                    cssClass="delete-comment-button" />
            </span>
        {/if}
    </div>
</div>

<script>
    import Avatar from '@itslearning/prometheus/assets/nodes/Avatar/v1/Avatar.svelte';
    import Button from '@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte';

    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let comment = {};
    export let terms = {};
    export let canEdit = false;

    function showProfileCard() {
        dispatch('showProfileCard', { personId: comment.personId });
    }

    function deleteComment() {
        dispatch('deleteComment', {
            commentId: comment.commentId,
            deleteUrl: comment.deleteUrl
        });
    }
</script>
