import 'prometheus.aaa.scss';
import 'prometheus.modern.scss';

import './theme.aaa.scss';
import './theme.modern.scss';

import { registerDocumentClickBubblingOnTop } from '@itslearning/atlas/frames/IframeClickEventHelper';

import App from './lib/App/App.svelte';

// @ts-ignore
const data = window.IndividualPortfolioData;

registerDocumentClickBubblingOnTop();
const currentTitle = window.document.title;

window.document.title = data.portfolio.title + " " + currentTitle;

const app = new App({
    target: document.getElementById('individual-portfolio'),
    props: data
});

export default app;
