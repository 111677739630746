<PortfolioPageLayout
    {breadcrumbs}
    {navigation}
    {addContentButtons}
    closeTerm={terms.close}
    menuTitle={terms.portfolioMenu}
    bannerImageUrl={portfolio.bannerImageUrl}
    on:navigateToPortfolio={loadPortfolioStartPage}
    on:navigateToItem={loadPortfolioItem}
>
    <div slot="bannerControls">
        <h1>{portfolio.title}</h1>
        {#if portfolio.accessLevelTitle}
            <span class="access-level">{portfolio.accessLevelTitle}</span>
        {/if}
    </div>

    <div slot="headerControls" class="options">
        {#if portfolio.canEdit}
            {#if showRemoveButton}
                <button type="button" aria-label={terms.delete} class="delete-link" on:click|stopPropagation={remove}></button>
            {/if}
            {#if showEditButton}
                <a href={portfolioItem.urls.edit} aria-label={terms.edit} class="edit-link"><!----></a>
            {/if}
            {#if showShareButton}
                <button type="button" aria-label={terms.shareDialogTerms.shareYourPortfolio} class="share-button" on:click={shareLink}></button>
            {/if}
            {#if showCopyButton}
                <button type="button" aria-label={copyPageDialogSettings.terms.copyPage} class="copy-button" on:click={copyPage}></button>
                {#if showCopyDialog}
                    <CopyPageDialog
                        portfolioItemId={portfolioItem.portfolioItemId}
                        portfolioItemTitle={portfolioItem.title}
                        portfolioId={portfolio.portfolioId}
                        settings={copyPageDialogSettings}
                        antiForgeryHeaderSettings={antiForgeryHeaderSettings}
                        on:cancel={closeCopyDialog}
                        on:copyFinished={onCopyFinished} />
                {/if}
                {#if copyReport}
                    <Modal heading={copyPageDialogSettings.terms.copyPage}>
                        <div slot="body" class="copy-report-container">
                            <Feedback type={copyReport.type} visible={true} closable={false} html={copyReport.html} />
                            <Button
                                id={closeCopyReportButtonId}
                                bind:this={closeCopyReportButton}
                                type="secondary"
                                text={copyPageDialogSettings.terms.close}
                                htmlType="button"
                                on:click={closeCopyReport} />
                        </div>
                    </Modal>
                {/if}
            {/if}

        {/if}
    </div>

    <div slot="content">
        {#if showLoading}
            <LoadingState visible size="large" />
        {:else if showError}
            <Feedback type="warning" visible closable={false} text={terms.errorOccurred} />
        {:else if showPortfolio}
            <StartPage
                {portfolio}
                {antiForgeryHeaderSettings}
                terms={startPage.terms}
                exportSettings={startPage.exportSettings}
                recentPortfolioItems={startPage.recentPortfolioItems}
                showDisabledMessage={startPage.showDisabledMessage}
                rssFeedUrl={startPage.rssFeedUrl}
                assessment={startPage.assessment}
                on:share={shareLink}
                on:deletePortfolio={remove} />
        {:else if showPortfolioItem}
            <PortfolioItem
                {portfolioItem}
                {terms}
                previewMode={false}
                canEdit={portfolio.canEdit}
                {antiForgeryHeaderSettings}
                {personProfilePictureUrl} />
        {/if}

        {#if showShareDialog}
            <ShareDialog bind:this={shareDialog} publicUrl={portfolio.urls.publicAccess} terms={terms.shareDialogTerms} on:modalClosed={closeShareDialog} />
        {/if}

        <div bind:this={confirm}>
            {#if showDeleteConfirmation}
                <Confirm
                    heading={terms.pleaseConfirm}
                    on:modalConfirmed={onDeleteConfirmed}
                    on:modalCancelled={onConfirmClosed}
                    body={showPortfolioItem ? terms.portfolioItemDeleteConfirmation : terms.portfolioDeleteConfirmation}
                    {...confirmButtonText}
                    destructive />
            {/if}
        </div>
    </div>
</PortfolioPageLayout>

<script>
    import { onMount, tick } from 'svelte';

    import LoadingState from '@itslearning/prometheus/assets/nodes/LoadingSpinner/v1/LoadingSpinner.svelte';
    import PortfolioPageLayout from '@its-ui/eportfolio-common/Layouts/Portfolio/PortfolioPageLayout.svelte';
    import ShareDialog from '@its-ui/eportfolio-common/ShareDialog/ShareDialog.svelte';
    import Feedback from '@itslearning/prometheus/assets/feedback/Banner/v1/Banner.svelte';
    import Confirm from '@itslearning/prometheus/assets/modals/Confirm/v1/Confirm.svelte';
    import Modal from '@itslearning/prometheus/assets/modals/Modal/v1/Modal.svelte';
    import Button from '@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte';
    import StartPage from '@its-ui/eportfolio-common/StartPage/StartPage.svelte';
    import CopyPageDialog from '@its-ui/eportfolio-common/CopyPageDialog/CopyPageDialog.svelte';
    import PortfolioItem from '@its-ui/eportfolio-common/PortfolioItem/PortfolioItem.svelte';
    import { get, del } from '@itslearning/atlas/network/fetch';
    import { createAntiForgeryHeader } from '@its-ui/eportfolio-common/common';
    import { contentMode } from '@its-ui/eportfolio-common/constants';

    export let navigation = {};
    export let portfolio = {};
    export let terms = {};
    export let currentContentMode = contentMode.showPortfolioDescription;
    export let portfolioItem = {};
    export let showDeleteConfirmation = false;
    export let selectedPortfolioItemUrl = undefined;
    export let showShareDialog = false;
    export let breadcrumbs = {};
    export let startPage = {};
    export let showCopyDialog = false;
    export let copyReport = undefined;
    export let antiForgeryHeaderSettings = undefined;
    export let personProfilePictureUrl;
    export let addContentButtons = {};
    export let copyPageDialogSettings = {};

    $: showLoading = currentContentMode === contentMode.loadingInProgress;
    $: showError = currentContentMode === contentMode.errorOccurred;
    $: showPortfolio = currentContentMode === contentMode.showPortfolioDescription && portfolio;
    $: showPortfolioItem = currentContentMode === contentMode.showPortfolioItem && portfolioItem && portfolioItem.title;
    $: showShareButton = (showPortfolioItem && portfolio.urls && portfolio.urls.publicAccess);
    $: showEditButton = showPortfolioItem && portfolioItem.urls && portfolioItem.urls.edit;
    $: showRemoveButton = showPortfolioItem;
    $: showCopyButton = showPortfolioItem && portfolioItem.canCopy;
    $: confirmButtonText = ({
        i18n: { CONFIRM: terms.delete, CANCEL: terms.cancel }
    });

    /** @type ShareDialog */
    let shareDialog;
    let confirm, closeCopyReportButton;

    const closeCopyReportButtonId = 'close_report_dialog_button';

    onMount(() => {
        const portfolioItemUrl = navigation.selectedPortfolioItemUrl;

        if (portfolioItemUrl) {
            loadPortfolioItemByUrl(portfolioItemUrl);
        } else {
            updateNavigation(0);
        }
    });


    function loadPortfolioItem(event) {
        event.detail.originalEvent.preventDefault();
        startNavigation();
        loadPortfolioItemByUrl(event.detail.apiUrl);
    }

    function loadPortfolioItemByUrl(url) {
        return get(url)
            .then(data => {
                portfolioItem = data;
                selectedPortfolioItemUrl = undefined;
                currentContentMode = contentMode.showPortfolioItem;

                updateNavigation(data.portfolioItemId);
            })
            .catch(() => {
                currentContentMode = contentMode.errorOccurred;
                selectedPortfolioItemUrl = undefined;
            });
    }

    function loadPortfolioStartPage(event) {
        event.detail.originalEvent.preventDefault();
        startNavigation();
        loadPortfolioStartPageByUrl(event.detail.apiUrl);
    }

    function loadPortfolioStartPageByUrl(url) {
        get(url)
            .then(data => {
                portfolio.description = data.description;

                startPage = data;
                portfolio = portfolio;
                portfolioItem = undefined;
                selectedPortfolioItemUrl = undefined;
                currentContentMode = contentMode.showPortfolioDescription;

                updateNavigation(0);
            })
            .catch(() => {
                currentContentMode = contentMode.errorOccurred;
            });
    }

    function updateNavigation(portfolioItemId) {
        navigation.startPageLink.selected = currentContentMode === contentMode.showPortfolioDescription;
        navigation.groupedPortfolioItems.forEach(function (group) {
            group.items.forEach(function (item) {
                item.selected = item.id === portfolioItemId;

                if (item.id === portfolioItemId) {
                    item.badgeNumber = 0;
                }
            });
        });

        navigation = navigation;
    }

    async function shareLink() {
        showShareDialog = true;
        await tick();
        shareDialog.focusCopyButton();
    }

    function closeShareDialog() {
        showShareDialog = false;
    }

    async function remove() {
        showDeleteConfirmation = true;
        await tick();
        confirm.querySelector('.prom-button__destructive').focus();
    }

    function startNavigation() {
        currentContentMode = contentMode.loadingInProgress;
    }

    async function onDeleteConfirmed() {
        showDeleteConfirmation = false;
        await tick();

        if (currentContentMode === contentMode.showPortfolioItem && portfolioItem) {
            deletePortfolioItem(portfolioItem);
        } else {
            deletePortfolio();
        }
    }

    function onConfirmClosed() {
        showDeleteConfirmation = false;
    }

    function deletePortfolioItem(portfolioItem) {
        del(portfolioItem.urls.delete, undefined, {
            headers: createAntiForgeryHeader(antiForgeryHeaderSettings)
        })
            .then(() => {
                navigation.groupedPortfolioItems.forEach((group) => {
                    group.items = group.items.filter((item) => {
                        return item.id !== portfolioItem.portfolioItemId;
                    });
                });

                navigation = navigation;
                portfolioItem = undefined;

                loadPortfolioStartPageByUrl(navigation.startPageLink.apiUrl);
            })
            .catch(() => {
                currentContentMode = contentMode.errorOccurred;
            });
    }

    function deletePortfolio() {
        del(portfolio.urls.delete, undefined, {
            headers: createAntiForgeryHeader(antiForgeryHeaderSettings)
        })
            .then(data => {
                window.location.href = data;
            })
            .catch(() => {
                currentContentMode = contentMode.errorOccurred;
            });
    }

    async function copyPage() {
        showCopyDialog = true;
    }

    function closeCopyDialog() {
        showCopyDialog = false;
    }

    async function onCopyFinished(event) {
        closeCopyDialog();

        if (event.detail.targetPortfolioId === portfolio.portfolioId) {
            reloadNavigation();
        }

        copyReport = {
            type: event.detail.isSuccessful ? 'info' : 'warning',
            html: event.detail.message
        };
        await tick();

        document.querySelector(`#${closeCopyReportButtonId}`).focus();
    }

    function closeCopyReport() {
        copyReport = undefined;
    }

    function reloadNavigation() {
        get(portfolio.urls.getGroupedPortfolioItems)
            .then((items) => {
                navigation.groupedPortfolioItems = items;
                navigation = navigation;

                updateNavigation(portfolioItem.portfolioItemId);
            });
    }
</script>
