{#if portfolio.canEdit}
    <div class="toolbar-wrapper">
        <div class="toolbar">
            {#if portfolio.canEditDescription}
                <a href={portfolio.urls.editDescription} aria-label={terms.editStartPage} class="edit-toolbar-button">
                    <span class="toolbar-button-title">{terms.editStartPage}</span>
                </a>
            {/if}
            {#if portfolio.urls && portfolio.urls.publicAccess}
                <button type="button" aria-label={terms.sharePortfolio} class="share-toolbar-button" on:click={onShareButtonClick}>
                    <span class="toolbar-button-title">{terms.sharePortfolio}</span>
                </button>
            {/if}
            {#if portfolio.canExport}
                <button type="button" aria-label={terms.exportPortfolio} class="export-toolbar-button" on:click={onExportPortfolioClick}>
                    <span class="toolbar-button-title">{terms.exportPortfolio}</span>
                </button>
            {/if}
            {#if portfolio.canDelete}
                <button type="button" aria-label={terms.deletePortfolio} class="delete-toolbar-button" on:click={onDeletePortfolioClick}>
                    <span class="toolbar-button-title">{terms.deletePortfolio}</span>
                </button>
            {/if}
        </div>
    </div>

    {#if exportSettings}
        <Export
            currentExportResult={exportSettings.exportResult}
            exportUrl={exportSettings.exportUrl}
            iconUrl={exportSettings.exportIconUrl}
            terms={exportSettings.terms}
            {antiForgeryHeaderSettings} />
    {/if}

    {#if showDisabledMessage}
        <Banner type="warning" visible={true} html={terms.disabledMessage} closable={true} close={terms.close} />
    {/if}
{/if}

{#if rssFeedUrl}
    <section class={rssCssClass}>
        <h1>{terms.rssFeed}</h1>
        <div><a href={rssFeedUrl} target="_blank">{rssFeedUrl}</a></div>
    </section>
{/if}

{#if recentPortfolioItems && recentPortfolioItems.length > 0}
    <section class="recent-items">
        {#each recentPortfolioItems as portfolioItem}
            <div class="recent-item">
                <PortfolioItemPreview portfolioItem={portfolioItem} terms={terms} />
            </div>
        {/each}
    </section>
{:else}
    {#if assessment}
        <Assessment
            canAssess={assessment.canAssess}
            grade={assessment.grade}
            comment={assessment.comment}
            teacher={assessment.teacher}
            selectedAssessmentItemId={assessment.selectedAssessmentItemId}
            setAssessmentUrl={assessment.setAssessmentUrl}
            assessmentItems={assessment.assessmentItems}
            terms={assessment.terms}
            antiForgeryHeaderSettings={antiForgeryHeaderSettings}
            portfolioId={portfolio.portfolioId} />
    {/if}
    {#if showPortfolioWelcomeText}
        <div class="welcome">
            <h1>{terms.welcomeTitle}</h1>
            <p>{terms.welcomeDescription}</p>
            <p>{terms.startPortfolioDescription}</p>
        </div>
    {:else}
        <div class={portfolio.canEdit ? "description" : ""}>
            {@html portfolio.description}
        </div>
    {/if}
{/if}

<script>
    import { createEventDispatcher } from 'svelte';

    import Banner from '@itslearning/prometheus/assets/feedback/Banner/v1/Banner.svelte';
    import Assessment from '../Assessment/Assessment.svelte';
    import Export from '../Export/Export.svelte';
    import PortfolioItemPreview from '../PortfolioItem/PortfolioItem.svelte';
    import { dispatchEvent } from '../common';

    export let portfolio = {};
    export let terms = {};
    export let exportSettings = {};
    export let rssFeedUrl = '';
    export let antiForgeryHeaderSettings = {};
    export let showDisabledMessage = false;
    export let recentPortfolioItems = {};
    export let assessment = {};

    $: showPortfolioWelcomeText = portfolio.canEdit && (!portfolio.description || portfolio.description.length === 0);
    $: rssCssClass = getRssCssClass(portfolio);

    const dispatch = createEventDispatcher();

    function getRssCssClass(portfolio) {
        const cssClasses = ['rss'];

        if (!portfolio.canEdit) {
            cssClasses.push('no-margin');
        }

        return cssClasses.join(' ');
    }

    function onShareButtonClick() {
        dispatch('share');
    }

    function onDeletePortfolioClick(event) {
        dispatch('deletePortfolio', event.detail);
    }

    function onExportPortfolioClick() {
        dispatchEvent('startNewExport');
    }
</script>
