<section>
    <Modal heading={settings.terms.copyPage}>
        <div slot="body">
            <div class="copy-dialog-content">
                {#if showFeedback}
                    <Feedback type="warning" visible={true} closable={false} text={settings.terms.errorOccurred} />
                {/if}

                <span class="copy-dialog-label">{settings.terms.choosePortfolioToCopy.replace('{0}', portfolioItemTitle)}</span>

                <Select
                    id="portfolio"
                    name="portfolio"
                    label={settings.terms.portfolio}
                    disabled={!portfolioListLoaded}
                    value={selectedPortfolioId}
                    on:change={(event) => portfolioChanged(event.detail)}
                >
                    {#each portfolios as portfolio}
                        <option value={portfolio.key}>{portfolio.value}</option>
                    {/each}
                </Select>

                <Select
                    id="category"
                    name="category"
                    label={settings.terms.category}
                    disabled={!categoryListLoaded}
                    value={selectedCategoryId}
                    on:change={categoryChanged}
                >
                    {#each categories as category}
                        <option value={category.key}>{category.value}</option>
                    {/each}
                </Select>

                <Button 
                    type="primary"
                    text={settings.terms.copy}
                    htmlType="button"
                    disabled={copyButtonDisabled}
                    on:click={copyItem} />

                <Button
                    type="secondary"
                    text={settings.terms.cancel}
                    htmlType="button"
                    on:click={closeShareDialog} />
            </div>
        </div>
    </Modal>
</section>

<script>
    import { onMount, createEventDispatcher } from 'svelte';

    import Modal from '@itslearning/prometheus/assets/modals/Modal/v1/Modal.svelte';
    import Select from '@itslearning/prometheus/assets/inputs/Select/v1/Select.svelte';
    import Button from '@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte';
    import Feedback from '@itslearning/prometheus/assets/feedback/Banner/v1/Banner.svelte';
    import { createAntiForgeryHeader } from '../common';
    import { get, post } from '@itslearning/atlas/network/fetch';

    export let portfolioListLoaded = false;
    export let categoryListLoaded = false;
    export let portfolioItemId = 0;
    export let portfolioItemTitle = '';
    export let portfolioId = 0;
    export let settings = {};
    export let antiForgeryHeaderSettings = {};
    export let portfolios = [];
    export let categories = [];
    export let showFeedback = false;

    const dispatch = createEventDispatcher();

    let copyButtonDisabled = false;
    let selectedPortfolioId;
    let selectedCategoryId;

    onMount(async () => {
        try {
            portfolios = await get(settings.urls.getPortfolios);
            portfolioListLoaded = true;
            selectedPortfolioId = portfolioId;
            portfolioChanged(portfolioId);
        } catch {
            showError();
        }
    });

    function closeShareDialog() {
        dispatch('cancel');
    }

    function copyItem() {
        const data = {
            sourcePortfolioItemId: portfolioItemId,
            targetPortfolioId: selectedPortfolioId,
            targetCategoryId: selectedCategoryId
        };

        copyButtonDisabled = true;

        post(settings.urls.copyItem, data, getAntiForgeryHeader())
            .then((result) => {
                copyButtonDisabled = false;
                dispatch('copyFinished', {
                    isSuccessful: result.isSuccessful,
                    targetPortfolioId: data.targetPortfolioId,
                    message: result.message
                });
            })
            .catch(() => {
                copyButtonDisabled = false;
                showError();
            });
    }

    function portfolioChanged(portfolioId) {
        selectedPortfolioId = portfolioId;
        categoryListLoaded = false;
        const urls = settings.urls;

        get(urls.getCategoriesTemplate.replace(urls.portfolioIdParameter, selectedPortfolioId))
            .then((responseCategories) => {
                categories = responseCategories;
                categoryListLoaded = true;
                selectedCategoryId = 0;
            })
            .catch(() => {
                showError();
            });
    }

    function categoryChanged(event) {
        selectedCategoryId = event.detail;
    }

    function getAntiForgeryHeader() {
        return {
            headers: createAntiForgeryHeader(antiForgeryHeaderSettings)
        };
    }

    function showError() {
        showFeedback = true;
    }
</script>
