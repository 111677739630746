<section class="share-dialog">
    <Modal heading={terms.shareYourPortfolio} on:modalClosed={closeShareDialog} showCloseButton="true">
        <div slot="body">
            <div class="public-access">
                <input class="public-access-link" readonly value={publicUrl}/>
                <div class="copy-button">
                    <Button bind:this={copyButton} 
                        htmlType="button" 
                        type="primary" 
                        text={terms.copyUrl} 
                        on:click={copyPublicUrl} />
                </div>
            </div>
            <div class="feedback">
                <span class={copyFeedbackCssClass}>{terms.copyFeedback}</span>
            </div>
        </div>
    </Modal>
</section>


<script>
    import { createEventDispatcher } from 'svelte';

    import Modal from '@itslearning/prometheus/assets/modals/Modal/v1/Modal.svelte';
    import Button from '@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte';
    import { isIos } from '@itslearning/atlas/detectors/UserAgentHelper';

    export let publicUrl = '';
    export let terms = {};
    export let showCopyUrlFeedback = false;

    $: copyFeedbackCssClass = getCopyFeedbackCssClass(showCopyUrlFeedback);

    const dispatch = createEventDispatcher();

    let copyButton;

    function getCopyFeedbackCssClass(showCopyUrlFeedback) {
        const cssClasses = ['copy-feedback'];

        if (showCopyUrlFeedback) {
            cssClasses.push('visible');
        }

        return cssClasses.join(' ');
    }

    function copyPublicUrl() {
        let input;

        try {
            input = document.createElement('input');

            input.style.position = 'fixed';
            input.style.opacity = 0;
            input.value = publicUrl;

            document.body.appendChild(input);

            input.focus();

            if (isIos()) {
                input.setAttribute('readonly', true);
                input.setAttribute('contenteditable', true);

                const range = document.createRange();

                range.selectNodeContents(input);

                const selection = window.getSelection();

                selection.removeAllRanges();
                selection.addRange(range);

                input.setSelectionRange(0, input.value.length);
            } else {
                input.select();
            }

            document.execCommand('copy');
        } catch (err) {
            console.error(err);
        } finally {
            document.body.removeChild(input);
        }

        showCopyUrlFeedback = true;

        focusCopyButton();
    }

    function closeShareDialog() {
        dispatch('modalClosed');
    }

    export function focusCopyButton() {
        document.querySelector('.copy-button button').focus();
    }
</script>
