{#if model && (model.addItemUrl || model.addFolderUrl)}
    <section class="add-content-buttons {cssClass}">
        {#if model.addItemUrl}
            <a href={model.addItemUrl} class="add-item">{model.addItemLinkText}</a>
        {/if}
        {#if model.addFolderUrl}
            <a href={model.addFolderUrl} class="add-folder">{model.addFolderLinkText}</a>
        {/if}
    </section>
{/if}

<script>
    export let model = {};
    export let cssClass = '';
</script>