<div class="portfolio-page-layout-container">
    <aside class={sidebarHeaderCssClass}>
        <header class="sidebar-header">
            <section class="main-section">
                <h1>{menuTitle}</h1>
                <button type="button" aria-label={closeTerm} class="close-button" on:click|stopPropagation={() => toggleMenu(false)}></button>
            </section>

            <AddContentButtons model={addContentButtons} cssClass="header" />
        </header>

        <nav class="navigation">
            <ul>
                {#if navigation.backLink}
                    <NavigationItem settings={navigation.backLink} />
                {/if}
                {#if navigation.settingsLink}
                    <NavigationItem settings={navigation.settingsLink} />
                {/if}
                {#if navigation.startPageLink}
                    <NavigationItem settings={navigation.startPageLink} on:navigation={onPortfolioNavigate} />
                {/if}
                {#each navigation.groupedPortfolioItems as group}
                    <NavigationItem settings={group} isFolder={true}>
                        {#each group.items as item}
                            <ul>
                                <NavigationItem settings={item} on:navigation={onItemNavigate} />
                            </ul>
                        {/each}
                    </NavigationItem>
                {/each}
            </ul>
        </nav>

        <footer class="sidebar-footer">
            <AddContentButtons model={addContentButtons} cssClass="footer" />
        </footer>
    </aside>

    <section class="main" on:click|stopPropagation={() => toggleMenu(false)}>
        <header class="eportfolio-main-header">
            <div class="banner" style={bannerInlineStyle}></div>
            <div class="content-block">
                <div class="header-stop">
                    <slot name="bannerControls"></slot>
                </div>
            </div>
        </header>
        <div class="main-content">
            <div class="content-block">
                <article>
                    <header class="content-options">
                        <button type="button" class="toggle-sidebar-button" on:click|stopPropagation={() => toggleMenu(true)}>{menuTitle}</button>
                        <slot name="headerControls"></slot>
                    </header>

                    <slot name="content"></slot>
                </article>
            </div>
        </div>
    </section>
</div>

<script>
    import { createEventDispatcher, tick } from 'svelte';

    import NavigationItem from '../../Navigation/Item.svelte';
    import AddContentButtons from '../../AddContentButtons/AddContentButtons.svelte';

    const dispatch = createEventDispatcher();

    export let closeTerm = '';
    export let menuTitle = '';
    export let navigation = {};
    export let bannerImageUrl = '';
    export let showSidebarOnSmallScreens = false;
    export let addContentButtons = {};

    $: sidebarHeaderCssClass = getSidebarHeaderCssClass(showSidebarOnSmallScreens);
    $: bannerInlineStyle = getBannerInlineStyle(bannerImageUrl);

    function getSidebarHeaderCssClass(showSidebarOnSmallScreens) {
        const cssClasses = ['sidebar'];

        if (showSidebarOnSmallScreens) {
            cssClasses.push('visible');
        }

        return cssClasses.join(' ');
    }

    function getBannerInlineStyle(bannerImageUrl) {
        const gradient = 'linear-gradient(to top, rgba(0, 0, 0, 0.54) 40%, rgba(0, 0, 0, 0))';

        return bannerImageUrl
            ? `background-image: ${gradient}, url('${bannerImageUrl}')`
            : `background-image: ${gradient}`;
    }

    async function toggleMenu(showSidebar) {
        showSidebarOnSmallScreens = showSidebar;

        if (showSidebar) {
            const sidebar = document.getElementsByClassName('sidebar');

            if (sidebar.length > 0) {
                const closeButton = sidebar[0].getElementsByClassName('close-button');

                if (closeButton.length > 0) {
                    await tick();
                    closeButton[0].focus();
                }
            }
        }
    }

    function onPortfolioNavigate(event) {
        toggleMenu(false);
        dispatch('navigateToPortfolio', {
            apiUrl: event.detail.apiUrl,
            originalEvent: event.detail.event
        });
    }

    function onItemNavigate(event) {
        toggleMenu(false);
        dispatch('navigateToItem', {
            apiUrl: event.detail.apiUrl,
            originalEvent: event.detail.event
        });
    }
</script>
