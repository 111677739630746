{#if iconUrl}
    <img class="icon" src={iconUrl} alt="" />
{/if}
{#if isDraft}
    <span class="draft-icon"></span>
{/if}

<span class="text">{text}</span>

{#if badgeNumber > 0}
    <span class="screen-reader">{badgeTitle}</span>
    <span class="badge"  aria-hidden="true">{badgeNumber}</span>
{/if}

<script>
    export let iconUrl = '';
    export let text = '';
    export let badgeNumber = 0;
    export let badgeTitle = '';
    export let isDraft = false;
</script>